<template>
  <BoardLayout mainLabel="Projects" :tabs="tabs"></BoardLayout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BoardLayout from '@/components/templates/BoardLayout.vue'
export default defineComponent({
  components: {
    BoardLayout
  },
  setup() {
    const tabs = [
      {
        routerName: 'ProjectListing',
        routerText: 'Active projects',
        routerIcon: 'Yes',
        tooltipText: 'Click on each project to view project dashboard, edit details and set-up invoicing.',
        tooltipTitle: 'Active projects'
      },
      { routerName: 'ArchiveProjects', routerText: 'Archive' }
      // { routerName: 'Team', routerText: 'Pipeline' }
    ]
    return { tabs }
  }
})
</script>
